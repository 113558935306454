
import Splitting from "splitting";
(function(){
  
  let onScroll = function(){
    $(window).scroll(function () {
        if ($(window).scrollTop() > 30)
            $('header').addClass('stick')
        else
            $('header').removeClass('stick')
    })
  }
  
  let menu = function(){
      $('body').toggleClass('noScroll');
  };
    
  let homeBnrs = function(){
    $('.mv-banners').slick({
      arrows: false,
      dots:true,
      slidesToScroll: 1,
      slidesToShow:1,
      infinite:false
    });
  }

  let sldrsRecipes = function(){

      $('.vm-recipeSldr').slick({
          arrows: false,
          dots: false,
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite:true,
          centerMode: true,
          centerPadding: '100px',
          //variableWidth: true,
          responsive: [

              {
                breakpoint: 9999999,
                settings: {
                  centerPadding: '100px',
                  
                }
              },
              {
                breakpoint: 1500,
                settings: {
                  centerPadding: '40px',
                  slidesToShow: 3,
                }
              },
              {
                breakpoint: 991,
                settings: {
                  centerPadding: '40px',
                  slidesToShow: 3,
                 
                }
              },
              {
                breakpoint: 480,
                settings: {
                  centerPadding: '40px',
                  slidesToShow: 1
                }
              }
            ]
      });
  }


  let modalFacts = function(){
   const elTarget = document.querySelector('[data-target]');

  //  elTarget.forEach(el=>{
  //   console.log(el);
  // })
 
    if($(elTarget).length > 0){
      $(elTarget).on('click', function(){
        $('.modal-Box').addClass('show-modal');
      });
      $('.modal-close').on('click', function(){
        $('.modal-Box').removeClass('show-modal');
      });
    }

  }

  $('.menuMb .hamburger').on('click', menu);
  Splitting();

  sldrsRecipes();
  onScroll();
  modalFacts();
  homeBnrs();

 })();
window.Contact = new (function () {

    var that = this;
    var $map = $('#mapStores');

    /**
     * Init
     */
    this.init = function () {

        //fancy radio button
        $('.radio').on('click', that.fancyRadio);

        //Toggle store locator form
        $('.showHideStoreForm').on('click', that.showHideStoreLocationForm);

        //Search stores
        $('.wrap-list form button').on('click', that.searchStores);

        //prevent default form
        $('.searchStoreForm').on('submit', function (e){
            e.preventDefault();
        });
        $('.searchStoreForm input').keypress(function(event){
            var keycode = (event.keyCode ? event.keyCode : event.which);
            if(keycode == '13'){
                that.searchStores();
            }
        });

        //MAP
        that.map();
    };

    /**
     * Fancy radio
     */
    this.fancyRadio = function () {

        $('input[type="radio"]').parent().removeClass('checked');

        if( $(this).find('input[type="radio"]').is(':checked')) {
            $(this).addClass('checked');
        }

    };

    /**
     * Show/Hide Store Locatior form
     */
    this.showHideStoreLocationForm = function () {

        var addClss = $(this).data('who');

        $('.'+addClss).toggleClass('close');
        $(this).toggleClass('flip');

    };

    /**
     * Search stores
     */
    this.searchStores = function () {

        $('.wrap-list .search-panel').removeClass('d-none');

        var searchFor = $('.wrap-list form input').val();

        $.ajax({
            type: 'POST',
            url: searchForStoresRoute,
            data: {
                '_token': $(document).find('meta[name="csrf-token"]').attr("content"),
                'search_for': searchFor,
            },
            success: function (response) {

                $('.wrap-list .list-content .item').addClass('d-none');
                $('.wrap-list .list-content .item.store-not-found').remove();

                var currentStores = $('.wrap-list .list-content .item');
                var htmlToAdd = '';

                $.each(response, function (index, value){

                    $.each(currentStores, function (i, v) {
                        var nameStore = $(v).data('name');

                        if( nameStore == value.name) {
                            $(v).removeClass('d-none');
                            return;
                        }

                    });

                });

                if( response.length == 0) {
                    htmlToAdd += '<div class="item store-not-found">';
                    htmlToAdd += '<label class="d-block text-center">';
                    htmlToAdd += notStoreFinded;
                    htmlToAdd += '</label>';
                    htmlToAdd += '</div>';
                }

                $('.wrap-list .list-content').append(htmlToAdd);
                $('.wrap-list .search-panel').addClass('d-none');

                /*
                let map = that.initMapInstance();
                const bounds = new google.maps.LatLngBounds();

                that.addCoordsToMarkers(map, bounds);
                map.fitBounds(bounds);
                 */


            }, error: function (error) {
                $('.wrap-list .search-panel').addClass('d-none');
            }
        });

    };

    /**
     * Map
     */
    this.map = function () {

        let markers = new Array();

        for (var i = 0; i < markers.length; i++) {
            markers[i].setMap(null);
        }

        let mapOptions = {
            zoom: 15,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            center: new google.maps.LatLng(32.2217407, -110.9264832),
            panControl: !false,
            zoomControl: !false,
            mapTypeControl: false,
            scaleControl: true,
            streetViewControl: false,
            overviewMapControl: false,
            rotateControl: true,
            scrollwheel: false,
        };

        let map = that.initMapInstance();
        let bounds = new google.maps.LatLngBounds();

        that.addCoordsToMarkers(map, bounds);

        map.fitBounds(bounds);

        $(document).on('click', '.StoreName', function () {
            $('.Service-locator').removeClass('active');
            map.setCenter(this.marker_position);
            map.setZoom(17);
            $(this).addClass('active');
        });

        /*
        $('.StoreName').on('click', function () {
            console.log('ok');
            $('.Service-locator').removeClass('active');
            map.setCenter(this.marker_position);
            map.setZoom(17);
            $(this).addClass('active');
        });
         */
    };

    /**
     * add coords to markers
     * @param map
     * @param bounds
     */
    this.addCoordsToMarkers = function (map, bounds) {
        $('.vm-store-locator-wrap .wrap-list .list-content .item').each((i, divService) => {
            const position = new google.maps.LatLng(
                divService.dataset.lat,
                divService.dataset.lng
            );
            const marker = new google.maps.Marker({
                position,
                map
            });
            google.maps.event.addListener(marker, 'click', () => {
                const $divService = $(divService);
                $divService.click();
                //scrollTo($divService, $header.height() * 3);
            });
            divService.marker_position = position;
            bounds.extend(position);
        });
    };

    /**
     * Init map instance
     */
    this.initMapInstance = function () {
        let mapOptions = {
            zoom: 15,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            center: new google.maps.LatLng(32.2217407, -110.9264832),
            panControl: !false,
            zoomControl: !false,
            mapTypeControl: false,
            scaleControl: true,
            streetViewControl: false,
            overviewMapControl: false,
            rotateControl: true,
            scrollwheel: false,
        };

        return new google.maps.Map($map[0], mapOptions);
    };

})();

window.Filters = new (function(){

    var that = this;

    /**
     * Filtro por categoria
     */

    this.sldrFilters = function () {

        if ($('.vm-filters')) {

            $('.vm-filters').slick({
                arrows: false,
                dots: false,
                slidesToShow: 8,
                slidesToScroll: 1,
                infinite: false,
                variableWidth: true,
                responsive: [
                {
                    breakpoint: 1400,
                    settings: {
                    centerPadding: '40px',
                    slidesToShow: 6,
                    }
                },
                {
                    breakpoint: 1025,
                    settings: {
                    centerPadding: '40px',
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                    centerPadding: '40px',
                    slidesToShow: 5,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1
                    }
                }
                ]
            });

          let links = $('.vm-filterLink');

          links.each((item,elem) => {
            let $elem = $(elem);
            $elem.on('click',function(){
              $('.vm-filterLink').removeClass('current');
              $(this).addClass('current');
            })
          });

        }
      }

    /**
     * Filtro por producto
     */
    this.dropDownFilter = function () {
        let dropHandler = $('.filter-handler'),
            dropDown = $('.vm-recipes_listProd'),
            items = dropDown.find('a'),
            currentOpt = $('.vm-recipes_current');

        dropHandler.on('click', function () {
            $(this).toggleClass('active');
            dropDown.toggleClass('showOptions');
        });

        items.each((index, elem) => {
            let el = $(elem);

            el.on('click', function (e) {
                //e.preventDefault();
                currentOpt.text(el.data("product"));
                dropDown.toggleClass('showOptions');
            });

        });

        currentOpt.text(items.first().data("product"));

    }

    this.expandSearch = function () {
        let searchBox = $('.vm-recipes_searchBox'),
            handler = $('.search-icon'),
            breackpoint = 767,
            mql = window.matchMedia("(max-width:" + breackpoint + "px)");

        function mediaqueryresponse(mql) {
            if (mql.matches) {
                handler.on('click', function () {
                    searchBox.toggleClass('expand');
                });
            }
        }

        mediaqueryresponse(mql)

        mql.addEventListener('change', mediaqueryresponse);
    }

    /**
     * Init
     */
    this.init = function () {

        that.dropDownFilter();
        that.sldrFilters();
        that.expandSearch();
    };

})();
